.range-slider {
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}
.range-slider[data-vertical] {
  height: 100%;
  width: 8px;
}
.range-slider[data-disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.range-slider .range-slider__thumb {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #2196f3;
}
.range-slider .range-slider__thumb:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 6px rgba(33, 150, 243, 0.5);
}
.range-slider[data-vertical] .range-slider__thumb {
  left: 50%;
}
.range-slider .range-slider__thumb[data-disabled] {
  z-index: 2;
}
.range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  width: 100%;
  height: 100%;
  background: #51adf6;
}
.range-slider[data-vertical] .range-slider__range {
  left: 50%;
  transform: translate(-50%, 0);
}
.range-slider input[type="range"] {
  -webkit-appearance: none;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
}
.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}
.range-slider input[type="range"]::-moz-range-thumb {
  width: 0;
  height: 0;
  border: 0;
}
.range-slider input[type="range"]:focus {
  outline: 0;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@font-face {
  font-family: 'scm';
  src: url(/_next/static/media/scm.ccfff336.eot);
  src: url(/_next/static/media/scm.ccfff336.eot#iefix) format('embedded-opentype'), url(/_next/static/media/scm.d6fa2bdc.ttf) format('truetype'),
    url(/_next/static/media/scm.abb61f7b.woff) format('woff'), url(/_next/static/media/scm.0ab23733.svg#scm) format('svg');
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'scm' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-express-production:before {
  content: '\e9be';
}
.icon-help-question .path1:before {
  content: '\e9d6';
  color: rgb(0, 38, 62);
}
.icon-help-question .path2:before {
  content: '\e9d7';
  margin-left: -1.0458984375em;
  color: rgb(255, 255, 255);
}
.icon-red-info .path1:before {
  content: '\e9cd';
  color: rgb(249, 40, 35);
}
.icon-red-info .path2:before {
  content: '\e9ce';
  margin-left: -1em;
  color: rgb(249, 40, 35);
}
.icon-red-info .path3:before {
  content: '\e9cf';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-upload-new:before {
  content: '\e9d0';
}
.icon-dieline:before {
  content: '\e9d1';
  color: #fff;
}
.icon-upload-dark:before {
  content: '\e9d2';
}
.icon-file-checker:before {
  content: '\e9d3';
  color: #fff;
}
.icon-simple-download:before {
  content: '\e9d4';
}
.icon-download-new:before {
  content: '\e9d5';
}
.icon-close-circle-s .path1:before {
  content: '\e9c8';
  color: rgb(114, 116, 123);
}
.icon-close-circle-s .path2:before {
  content: '\e9c9';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-order-set-s:before {
  content: '\e9ca';
}
.icon-upload-set:before {
  content: '\e9cb';
}
.icon-folder-set-black:before {
  content: '\e9cc';
}
.icon-plus-icon-black .path1:before {
  content: '\e9c6';
  color: rgb(0, 38, 62);
}
.icon-plus-icon-black .path2:before {
  content: '\e9c7';
  margin-left: -1em;
  color: rgb(246, 197, 74);
}

.icon-group-set:before {
  content: '\e9c2';
}
.icon-x:before {
  content: '\e9c3';
  color: #f92626;
}
.icon-order-ico:before {
  content: '\e9c4';
}
.icon-upload-simple:before {
  content: '\e9c5';
}
.icon-plus-icon-black .path1:before {
  content: '\e9c6';
  color: rgb(0, 38, 62);
}
.icon-plus-icon-black .path2:before {
  content: '\e9c7';
  margin-left: -1em;
  color: rgb(246, 197, 74);
}

.icon-artwork-service1:before {
  content: '\e9bf';
}
.icon-design-service:before {
  content: '\e9c0';
}
.icon-scroll-left:before {
  content: '\e9b7';
}
.icon-scroll-right:before {
  content: '\e9b8';
}
.icon-artwork-print:before {
  content: '\e9bb';
}
.icon-artwork-pdf:before {
  content: '\e9bc';
  color: #0292c2;
}
.icon-tick-and-circle:before {
  content: '\e9ba';
  color: #fefefe;
}
.icon-messages-multi:before {
  content: '\e9b5';
}
.icon-email-messages:before {
  content: '\e9b6';
}
.icon-phone-call-lf:before {
  content: '\e9b7';
}
.icon-settings-black:before {
  content: '\e9b8';
}
.icon-settings-dollor:before {
  content: '\e9b9';
}

.icon-proof-log-in:before {
  content: '\e9a8';
  color: #0292c2;
}
.icon-proof-order-confirmation:before {
  content: '\e9a9';
  color: #0292c2;
}
.icon-proof-blue-truck:before {
  content: '\e9aa';
  color: #0292c2;
}
.icon-proof-black-truck:before {
  content: '\e9ab';
}
.icon-proof-production-time:before {
  content: '\e9ac';
}
.icon-proof-three-varieties:before {
  content: '\e9ad';
}
.icon-proof-no-cut .path1:before {
  content: '\e9ae';
  color: rgb(0, 39, 64);
}
.icon-proof-no-cut .path2:before {
  content: '\e9af';
  margin-left: -0.966796875em;
  color: rgb(0, 39, 64);
}
.icon-proof-no-cut .path3:before {
  content: '\e9b0';
  margin-left: -0.966796875em;
  color: rgb(255, 255, 255);
}
.icon-proof-no-cut .path4:before {
  content: '\e9b1';
  margin-left: -0.966796875em;
  color: rgb(255, 255, 255);
}
.icon-proof-cutting:before {
  content: '\e9b2';
}
.icon-proof-roll:before {
  content: '\e9b3';
}
.icon-proof-varnish:before {
  content: '\e9b4';
}
.icon-others .path1:before {
  content: '\e99c';
  color: rgb(255, 255, 255);
}
.icon-others .path2:before {
  content: '\e99d';
  margin-left: -1.0185546875em;
  color: rgb(58, 58, 58);
}
.icon-others .path3:before {
  content: '\e99e';
  margin-left: -1.0185546875em;
  color: rgb(255, 255, 255);
}
.icon-others .path4:before {
  content: '\e99f';
  margin-left: -1.0185546875em;
  color: rgb(255, 255, 255);
}
.icon-others .path5:before {
  content: '\e9a0';
  margin-left: -1.0185546875em;
  color: rgb(255, 255, 255);
}
.icon-others .path6:before {
  content: '\e9a1';
  margin-left: -1.0185546875em;
  color: rgb(255, 255, 255);
}
.icon-others .path7:before {
  content: '\e9a2';
  margin-left: -1.0185546875em;
  color: rgb(255, 255, 255);
}
.icon-others .path8:before {
  content: '\e9a3';
  margin-left: -1.0185546875em;
  color: rgb(255, 255, 255);
}
.icon-plastic-container:before {
  content: '\e9a4';
}
.icon-jar:before {
  content: '\e9a5';
}
.icon-bag-pouch:before {
  content: '\e9a6';
}
.icon-bottle:before {
  content: '\e9a7';
}
.icon-phone-call:before {
  content: '\e998';
}
.icon-chat:before {
  content: '\e999';
}
.icon-contact-form:before {
  content: '\e99a';
}
.icon-message:before {
  content: '\e99b';
}
.icon-pdf-cco:before {
  content: '\e986';
}
.icon-info-bulb:before {
  content: '\e959';
}
.icon-safety .path1:before {
  content: '\e984';
  color: rgb(0, 39, 62);
}
.icon-safety .path2:before {
  content: '\e985';
  margin-left: -0.8623046875em;
  color: rgb(244, 246, 249);
}
.icon-color-mode .path1:before {
  content: '\e987';
  color: rgb(73, 189, 232);
}
.icon-color-mode .path2:before {
  content: '\e989';
  margin-left: -0.77734375em;
  color: rgb(248, 235, 52);
}
.icon-color-mode .path3:before {
  content: '\e98e';
  margin-left: -0.77734375em;
  color: rgb(35, 31, 32);
}
.icon-color-mode .path4:before {
  content: '\e994';
  margin-left: -0.77734375em;
  color: rgb(236, 0, 138);
}
.icon-color-mode .path5:before {
  content: '\e995';
  margin-left: -0.77734375em;
  color: rgb(35, 31, 32);
}
.icon-color-mode .path6:before {
  content: '\e996';
  margin-left: -0.77734375em;
  color: rgb(0, 39, 62);
}
.icon-color-mode .path7:before {
  content: '\e997';
  margin-left: -0.77734375em;
  color: rgb(0, 39, 62);
}
.icon-standared-resolution-line-art:before {
  content: '\e95a';
}
.icon-standared-resolution:before {
  content: '\e95d';
}
.icon-min-resolution:before {
  content: '\e95e';
}
.icon-font-path:before {
  content: '\e95f';
}
.icon-bleed:before {
  content: '\e9c1';
}
.icon-go-back .path1:before {
  content: '\e956';
  color: rgb(255, 255, 254);
}
.icon-go-back .path2:before {
  content: '\e957';
  margin-left: -1em;
  color: rgb(193, 193, 193);
}
.icon-go-back .path3:before {
  content: '\e958';
  margin-left: -1em;
  color: rgb(193, 193, 193);
}
.icon-new-durability:before {
  content: '\e98f';
}
.icon-new-permanent .path1:before {
  content: '\e990';
  color: rgb(255, 255, 255);
}
.icon-new-permanent .path2:before {
  content: '\e991';
  margin-left: -1.423828125em;
  color: rgb(0, 40, 61);
}
.icon-new-permanent .path3:before {
  content: '\e992';
  margin-left: -1.423828125em;
  color: rgb(0, 0, 0);
}
.icon-new-permanent .path4:before {
  content: '\e993';
  margin-left: -1.423828125em;
  color: rgb(0, 0, 0);
}
.icon-rectangular-slider-left .path1:before {
  content: '\e98a';
  color: rgb(216, 216, 216);
}
.icon-rectangular-slider-left .path2:before {
  content: '\e98b';
  margin-left: -0.4814453125em;
  color: rgb(188, 188, 188);
}
.icon-rectangular-slider-right .path1:before {
  content: '\e98c';
  color: rgb(216, 216, 216);
}
.icon-rectangular-slider-right .path2:before {
  content: '\e98d';
  margin-left: -0.4814453125em;
  color: rgb(188, 188, 188);
}
.icon-wire:before {
  content: '\e988';
}
.icon-info-set .path1:before {
  content: '\e980';
  color: rgb(190, 190, 190);
}
.icon-info-set .path2:before {
  content: '\e981';
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-info-set .path3:before {
  content: '\e982';
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-info-set .path4:before {
  content: '\e983';
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-grey-info .path1:before {
  content: '\e952';
  color: rgb(231, 231, 231);
}
.icon-grey-info .path2:before {
  content: '\e953';
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.icon-grey-info .path3:before {
  content: '\e954';
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.icon-grey-info .path4:before {
  content: '\e955';
  margin-left: -1.1337890625em;
  color: rgb(255, 255, 255);
}
.icon-red-yellow-circle .path1:before {
  content: '\e97e';
  color: rgb(239, 32, 27);
}
.icon-red-yellow-circle .path2:before {
  content: '\e97f';
  margin-left: -1em;
  color: rgb(255, 183, 6);
}
.icon-pdf-upload:before {
  content: '\e97d';
}
.icon-custom-sheet:before {
  content: '\e978';
}
.icon-small-roll .path1:before {
  content: '\e979';
  color: rgb(9, 38, 60);
}
.icon-small-roll .path2:before {
  content: '\e97a';
  margin-left: -1.0166015625em;
  color: rgb(255, 255, 255);
}
.icon-small-roll .path3:before {
  content: '\e97b';
  margin-left: -1.0166015625em;
  color: rgb(255, 255, 255);
}
.icon-small-roll .path4:before {
  content: '\e97c';
  margin-left: -1.0166015625em;
  color: rgb(199, 199, 199);
}
.icon-dimension:before {
  content: '\e974';
  color: #bfbfbf;
}
.icon-quantity-icon:before {
  content: '\e975';
  color: #bfbfbf;
}
.icon-material-icon:before {
  content: '\e976';
  color: #bfbfbf;
}
.icon-roll-icon:before {
  content: '\e977';
  color: #bfbfbf;
}
.icon-artwork-service:before {
  content: '\e973';
}
.icon-partial-varnish .path1:before {
  content: '\e96a';
  color: rgb(9, 38, 60);
}
.icon-partial-varnish .path2:before {
  content: '\e96b';
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}
.icon-partial-varnish .path3:before {
  content: '\e96c';
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}
.icon-partial-varnish .path4:before {
  content: '\e96d';
  margin-left: -1.017578125em;
  color: rgb(199, 199, 199);
}
.icon-material .path1:before {
  content: '\e96e';
  color: rgb(9, 38, 60);
}
.icon-material .path2:before {
  content: '\e96f';
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}
.icon-material .path3:before {
  content: '\e970';
  margin-left: -1.017578125em;
  color: rgb(255, 255, 255);
}
.icon-material .path4:before {
  content: '\e971';
  margin-left: -1.017578125em;
  color: rgb(199, 199, 199);
}
.icon-material .path5:before {
  content: '\e972';
  margin-left: -1.017578125em;
  color: rgb(0, 0, 0);
}

.icon-recycle:before {
  content: '\e969';
}

.icon-circle-unfilled:before {
  content: '\e950';
}
.icon-circle-filled:before {
  content: '\e966';
}
.icon-eco .path1:before {
  content: '\e967';
  color: rgb(0, 0, 0);
}
.icon-eco .path2:before {
  content: '\e968';
  margin-left: -0.896484375em;
  color: rgb(248, 248, 248);
}
.icon-minus-xl:before {
  content: '\e965';
}
.icon-plux-xl:before {
  content: '\e964';
}

.icon-slide-left .path1:before {
  content: '\e960';
  color: rgb(255, 255, 255);
}
.icon-slide-left .path2:before {
  content: '\e961';
  margin-left: -1em;
  color: rgb(0, 38, 62);
}
.icon-slide-right .path1:before {
  content: '\e962';
  color: rgb(244, 246, 249);
}
.icon-slide-right .path2:before {
  content: '\e963';
  margin-left: -1em;
  color: rgb(0, 38, 62);
}
.icon-reload .path1:before {
  content: '\e951';
  color: rgb(255, 255, 254);
}
.icon-reload .path2:before {
  content: '\e95c';
  margin-left: -1em;
  color: rgb(193, 193, 193);
}
.icon-fsc:before {
  content: '\e94f';
  color: #03593e;
}
.icon-liquid-resistance:before {
  content: '\e95b';
}
.icon-productionexpress .path1:before {
  content: '\e94d';
  color: rgb(0, 38, 62);
}
.icon-productionexpress .path2:before {
  content: '\e94e';
  margin-left: -1em;
  color: rgb(0, 143, 190);
}

.icon-gear-production-standard:before {
  content: '\e94c';
  color: #008fbe;
}

.icon-amex:before {
  content: '\e93e';
  color: #006fcf;
}
.icon-mastercard .path1:before {
  content: '\e93f';
  color: rgb(255, 95, 0);
}
.icon-mastercard .path2:before {
  content: '\e940';
  margin-left: -1.6181640625em;
  color: rgb(235, 0, 27);
}
.icon-mastercard .path3:before {
  content: '\e941';
  margin-left: -1.6181640625em;
  color: rgb(247, 158, 27);
}
.icon-mastercard .path4:before {
  content: '\e942';
  margin-left: -1.6181640625em;
  color: rgb(247, 158, 27);
}
.icon-paypal .path1:before {
  content: '\e943';
  color: rgb(0, 48, 135);
}
.icon-paypal .path2:before {
  content: '\e944';
  margin-left: -3.5419921875em;
  color: rgb(0, 112, 224);
}
.icon-paypal .path3:before {
  content: '\e945';
  margin-left: -3.5419921875em;
  color: rgb(0, 48, 135);
}
.icon-paypal .path4:before {
  content: '\e946';
  margin-left: -3.5419921875em;
  color: rgb(0, 112, 224);
}
.icon-paypal .path5:before {
  content: '\e947';
  margin-left: -3.5419921875em;
  color: rgb(0, 28, 100);
}
.icon-paypal .path6:before {
  content: '\e948';
  margin-left: -3.5419921875em;
  color: rgb(0, 112, 224);
}
.icon-paypal .path7:before {
  content: '\e949';
  margin-left: -3.5419921875em;
  color: rgb(0, 48, 135);
}
.icon-visa:before {
  content: '\e94a';
  color: #1a1f70;
}
.icon-spanish .path1:before {
  content: '\e937';
  color: rgb(170, 21, 27);
}
.icon-spanish .path2:before {
  content: '\e938';
  margin-left: -1.3330078125em;
  color: rgb(241, 191, 0);
}
.icon-english .path1:before {
  content: '\e939';
  color: rgb(1, 33, 105);
}
.icon-english .path2:before {
  content: '\e93a';
  margin-left: -1.3330078125em;
  color: rgb(255, 255, 255);
}
.icon-english .path3:before {
  content: '\e93b';
  margin-left: -1.3330078125em;
  color: rgb(200, 16, 46);
}
.icon-english .path4:before {
  content: '\e93c';
  margin-left: -1.3330078125em;
  color: rgb(255, 255, 255);
}
.icon-english .path5:before {
  content: '\e93d';
  margin-left: -1.3330078125em;
  color: rgb(200, 16, 46);
}
.icon-info:before {
  content: '\e90d';
}
.icon-cross:before {
  content: '\e90e';
}
.icon-email:before {
  content: '\e912';
}
.icon-express-delivery:before {
  content: '\e913';
}
.icon-eye:before {
  content: '\e915';
}
.icon-custom-roll:before {
  content: '\e94b';
}
.icon-upload-cloud:before {
  content: '\e936';
}
.icon-stars:before {
  content: '\e92f';
}
.icon-time:before {
  content: '\e930';
}
.icon-trash:before {
  content: '\e932';
}
.icon-upload:before {
  content: '\e934';
}
.icon-warning:before {
  content: '\e935';
}
.icon-youtube:before {
  content: '\e931';
}
.icon-person:before {
  content: '\e926';
}
.icon-phone:before {
  content: '\e927';
}
.icon-place:before {
  content: '\e928';
}
.icon-plus:before {
  content: '\e929';
}
.icon-printer:before {
  content: '\e92a';
}
.icon-quality:before {
  content: '\e92b';
}
.icon-search:before {
  content: '\e92c';
}
.icon-sheet:before {
  content: '\e92d';
}
.icon-truck:before {
  content: '\e92e';
}
.icon-logout:before {
  content: '\e91e';
}
.icon-menu:before {
  content: '\e91f';
}
.icon-minus-circle:before {
  content: '\e920';
}
.icon-paper-check:before {
  content: '\e921';
}
.icon-pdf:before {
  content: '\e923';
}
.icon-person-add:before {
  content: '\e924';
}
.icon-plus-circle:before {
  content: '\e925';
}
.icon-instagram:before {
  content: '\e916';
}
.icon-intersection:before {
  content: '\e917';
}
.icon-layout:before {
  content: '\e918';
}
.icon-leaf:before {
  content: '\e91b';
}
.icon-link:before {
  content: '\e91c';
}
.icon-linkedin:before {
  content: '\e91d';
}
.icon-alert:before {
  content: '\e909';
}
.icon-book:before {
  content: '\e90a';
}
.icon-cart:before {
  content: '\e90b';
}
.icon-checkmark:before {
  content: '\e90f';
}
.icon-play:before {
  content: '\e911';
}
.icon-checkmark-circle:before {
  content: '\e933';
}
.icon-close-circle:before {
  content: '\e908';
}
.icon-chevron-down:before {
  content: '\e910';
}
.icon-chevron-left:before {
  content: '\e905';
}
.icon-chevron-right:before {
  content: '\e906';
}
.icon-chevron-top:before {
  content: '\e907';
}
.icon-copy:before {
  content: '\e914';
}
.icon-data-sheet:before {
  content: '\e919';
}
.icon-edit:before {
  content: '\e91a';
}
.icon-facebook:before {
  content: '\e922';
}
.icon-industrial:before {
  content: '\e90c';
}
.icon-arrow-down:before {
  content: '\e900';
}
.icon-alternating:before {
  content: '\e901';
}
.icon-arrow-left:before {
  content: '\e902';
}
.icon-arrow-right:before {
  content: '\e903';
}
.icon-arrow-top:before {
  content: '\e904';
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/204a0a5e1cf12efb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2cc74b96cdc9383d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ae6a2fe692ea80aa-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c99ef75952aca458-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bd907a34e79d0cf5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7c1535108079abc4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8727a9eb9a9ed107-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cf1f69a0c8aed54d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7519ebedca413f1f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/8f91baacbcce7392-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/dec290caeb2cbe42-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f0496e21808c976b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/5fb25f343c7550ca-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7db6c35d839a711c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/b8442747db2a9bad-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_ee872a';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6245472ced48d3be-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_ee872a';src: local("Arial");ascent-override: 92.83%;descent-override: 30.94%;line-gap-override: 8.84%;size-adjust: 113.11%
}.__className_ee872a {font-family: '__Poppins_ee872a', '__Poppins_Fallback_ee872a'
}

@font-face {
font-family: '__jakarta_b17575';
src: url(/_next/static/media/8f84e60102d9dec7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
}

@font-face {
font-family: '__jakarta_b17575';
src: url(/_next/static/media/86b620a3f27d6ccc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__jakarta_b17575';
src: url(/_next/static/media/99a968f74b419cf2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__jakarta_Fallback_b17575';src: local("Arial");ascent-override: 98.16%;descent-override: 20.99%;line-gap-override: 0.00%;size-adjust: 105.75%
}.__className_b17575 {font-family: '__jakarta_b17575', '__jakarta_Fallback_b17575'
}

